
import request from "@/request/request";

//开台
export function Founding(data) {
    return request({
        url: "/clerk/table/founding",
        method: "post",
        data,
    });
}
//更新桌台
export function updatePeople(data) {
    return request({
      url: "/clerk/table/updatePeople",
      method: "post",
      data
    });
}

//导购员列表
export function requestUserClerkList() {
    return request({
        url: "/clerk/user/clerkList",
        method: "post",
    });
}
//订单详细
export function requestOrderDetail(data) {
    return request({
        url: "/clerk/order/detail",
        method: "post",
        data,
    });
}

//移菜
export function requestMoveMenu(data) {
    return request({
        url: "/clerk/order/movegoods",
        method: "post",
        data
    });
}

//换台
export function requestTableMove(data) {
    return request({
        url: "/clerk/table/move",
        method: "post",
        data
    });
}
//并台
export function requestTableMerge(data) {
    return request({
        url: "/clerk/table/merge",
        method: "post",
        data
    });
}
//一台多单
export function requestTableSplit(data) {
    return request({
        url: "/clerk/table/split",
        method: "post",
        data
    });
}
//撤台
export function backFounding(data) {
    return request({
        url: "/clerk/table/backFounding",
        method: "post",
        data
    });
}
//底部当日订单统计
export function tableBottom(data) {
    return request({
        url: "/clerk/order/tableBottom",
        method: "post",
        data
    });
}
