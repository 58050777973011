<template>
  <div>
    <a-modal
      :visible="isShow"
      title="取消预定"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handlecancel"
    >
      <p>
        确认取消预定桌台？
      </p>
    </a-modal>
  </div>
</template>
<script>
import {cancelReserve} from '@/request/reserve.js';
export default {
  props: ["isShow",'everyList'],
  data() {
    return {    
    };
  },
   watch:{
    isShow:{
      handler(){
        if(this.isShow){
          const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
              _this.handleOk()
            } 
          }    
        }
       
      },
      deep:true,
    }
  },
  methods: {
    handlecancel() {
       this.$emit('choose',true,false)
    },
    handleOk() {
      this.canReserRequest()    
    },
    canReserRequest(){ //取消登录接口
       const _this=this;
       cancelReserve({reserve_id:_this.everyList.id && _this.everyList.id})
        .then((res) => {
          if(res.data.status===200){
            this.$emit('choose',true,true)
              //  console.log(JSON.stringify(res.data.data.list));
            }               
          })
        .catch(result => {
            return false;
          })
    }
  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-btn {
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  font-size: 1.8vh;
  border-radius: 0.5vh;
  margin: 0.5vh 0 0.5vh 2vh;
  color: white;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.ant-modal-content{
   background:rgba(237, 238, 242, 1);
}
p{
  font-size: 1.8vh;
}
</style>