<template>
    <div class="reserveInfo">
        <div>
          <breadCrumb :siderContent="siderName" />
        </div>
        <div class="searchBox">
            <a-input-search placeholder="请输入姓名/手机号" style="width: 30vh" @change="onSearch" @search="onSearch" v-model="keywords" @pressEnter="onSearch"/>
        </div>
        <a-table :columns="columns" :data-source="members" rowKey="id" bordered
        @change="handleTableChange"
            :pagination="{
            current:current,
            total:total,
            pageSize:10,
        }"
        :locale="{emptyText: '暂无数据'}"
            >
            <span slot="action" slot-scope="text, record" class="operate">    
                <span class="activeColor" v-if="record.is_founding === 1" @click="finsh(record.id && record.id, record.table_id && record.table_id)">
                    <span>开台</span>
                </span>
                <span class="activeColor" v-if="record.is_founding === 1" @click="finishRe(record)" >完成</span>
                <span  class="activeColor" v-if="record.is_cancel === 1" @click="cancel(record)">
                <span>取消</span>
                </span>     
                <span  v-if="record.is_founding === 0" class="jin">
                <span>开台</span>
                    <span class="heng">———</span>
                </span>
                <span  v-if="record.is_founding === 0" class="jin">
                <span>完成</span>
                    <span class="heng">———</span>
                </span>
                <span  v-if="record.is_cancel === 0" class="jin">
                <span>取消</span>
                <span class="heng">———</span>
                </span>
            </span>
            <span slot="status" slot-scope="text" class="status">
                <span v-if="text==='已过期'" class="finish">{{text}}</span>
                <span v-if="text==='已取消'" class="can">{{text}}</span>
                <span v-if="text==='已确认'" class="que">{{text}}</span>
                <span v-if="text==='已完成'" class="finish">{{text}}</span>
            </span>
        </a-table>
        <cancel :isShow="showCancel" @choose="changeCancel(arguments)" :everyList="recordEvery"/>
    </div>
</template>
<script>
import addReserve from "./addReserve";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {reserveInfo,reserveList,finishReserve} from '@/request/reserve.js';
import { shopStorey } from "@/request/api";
import { compare } from "@/public/common";
import { mapState , mapActions } from "vuex";
import cancel from "./cancel";
const columns = [
  {
    title: "桌台",
    dataIndex: "table_name",
    ellipsis: true,
  },
  {
    title: "姓名",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    ellipsis: true,
  },
  {
    title: "就餐时间",
    dataIndex: "order_time",
    scopedSlots: { customRender: "way" },
  },
  {
    title: "人数",
    dataIndex: "people",
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    ellipsis: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    dataIndex: "order_clerk",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  data() {
    return {
      topList:[],//餐桌区域
      siderName: "预定信息", //sider切换内容
      members: [],
      total:0,//数据条数
      currents:1,//桌台预定每条
      storey_id:'',//区域
      form: {
        name: "",
      },
      items: [],
      totals:0,
      keywords:'',//输入框关键词
      columns,
      current:1,//当前页
      showAddREserve:false,
      curTablename:'',//点击新增当前的桌台名
      showCancel:false,//取消预定modal
         recordEvery:'',//每条列表信息
    };
  },
  components: {
    breadCrumb,addReserve,cancel
  },
  computed: {
    ...mapState(["topTabList",'activeName']),
  },
  mounted(){
    this.messageRequest();
    this.keywords="";
  },
  methods:{
    ...mapActions(['updateTabListAction','activeNameAction']),

    messageRequest(){//预定列表请求
      const _this=this;
       reserveList({
         listRows:10,
          page:_this.current,
          keywords:_this.keywords,
         })
        .then((res) => {
          if(res.data.status===200){
            _this.members=res.data.data.list.data && res.data.data.list.data;
            _this.total=res.data.data.list.total && res.data.data.list.total
            }               
          })
        .catch(result => {
            return false;
          })
    },

    onSearch() { //预订信息搜索
    this.current=1;
      this.messageRequest()
    },
    handleTableChange(e){ //点击分页
      this.current = e.current;
      this.messageRequest()
    },

    finsh(id,table_id){  //点击完成预定
      let i = this.topTabList.findIndex(item => item.name == 'Yingye')
      if(i == -1){
        let arr = JSON.parse(JSON.stringify(this.topTabList))
        arr.push({
          title:'营业',
          path:'/yingye',
          name: 'Yingye',
          isDetele:true
        })
        this.updateTabListAction(arr)
      }
      this.activeNameAction('Yingye')
      this.$router.push({ path: '/yingye', query: { reserve_id: id,table_id: table_id} })

    },
    cancel(record){ //取消预定
      this.showCancel=true;
      this.recordEvery=record;
    },
    changeCancel(number){  //关闭提示modal
      if(number[0]){
        this.showCancel=false;
      }
       if(number[1]){
        this.messageRequest();
      }  
    },
    finishReserveRequest(record){ //完成预定
       finishReserve({
         reserve_id:record&& record.id
         })
        .then((res) => {
          if(res.data.status===200){
              this.messageRequest();
            }               
          })
        .catch(result => {
            return false;
      })
    },
    finishRe(record){
      this.finishReserveRequest(record)
    }

  }
};
</script>

<style lang="less" scoped>
.reserveInfo {
    width: 100%;
    padding:0 20px;
    box-sizing: border-box;
    .searchBox{
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
    }
    .activeColor{
        color:rgba(255, 103, 0, 1);
        margin-left:1.5vw;
    }
    .operate{
        display:flex;
        span{
            cursor: pointer;
        }
    }
}
  
.jin{
  position:relative;
  color:rgba(158, 158, 158, 1);
  margin-left:1.5vw;
  .heng{
    color:rgba(158, 158, 158, 1);
    position:absolute;
    left:-6px;
    top:-2px;
  }
} 
.finish{
   color:rgba(158, 158, 158, 1);
}    
.que{
   color:red;
} 

/deep/th{
  background: rgba(248, 248, 255, 1) !important;
  font-size: 1.8vh;
}
/deep/td{
  background: white !important;
  font-size: 1.8vh;
}

/deep/th:nth-child(2){
  width:8vw !important;
}
/deep/th:nth-child(3){
  width:12vw !important;
}
/deep/th:nth-child(4){
  width:14vw !important;
}
/deep/th:nth-child(5){
  width:6vw !important;
}
/deep/th:nth-child(6){
  width:7vw !important;
}

/deep/td{
  padding:1.5vh !important;
}
/deep/.ant-pagination{
  margin-right:4vw;
} 
/deep/.ant-input{
  height:3.5vh;
  line-height: 3.5vh;
}


</style>