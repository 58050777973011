<template>
  <div>
    <a-modal
      :visible="isShow"
      title="新增预订"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      
      >
        <a-form-model-item label="选择桌台" prop="table_id" ref="table_id">
          <a-select v-model="form.table_id" placeholder="请选择桌台">
            <a-select-option 
              v-for="(item,index) in da"
              :key="index"
              :value="item.id"
            >
              {{item.table_name}}
            </a-select-option> 
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="顾客姓名" prop="name" ref="name">
          <a-input
           v-model="form.name"
          />
        </a-form-model-item>
        <a-form-model-item label="就餐时间" prop="order_time" ref="order_time">
          <a-date-picker
            :locale="locale"
            class="myDate"
            placeholder="请选择时间"
            type="date"
           format="YYYY-MM-DD"
           @change="onChange"
           v-model="form.date"
            
          />
          <a-time-picker
            class="myTime"
            placeholder="请选择时间"
            format="HH:mm"
             v-model="form.time"
            @change="onChanges"
          />
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="mobile" ref="mobile">
          <a-input
            v-model="form.mobile"
          />
        </a-form-model-item>
        <a-form-model-item label="就餐人数" prop="people" ref="people">
          <a-input
            v-model="form.people"
          />
        </a-form-model-item>
        <a-form-model-item label="店员" prop="order_clerk_id" ref="order_clerk_id">
          <a-select v-model="form.order_clerk_id" placeholder="请选择店员">
            <a-select-option 
              v-for="(item,index) in userArr"
              :key="index"
              :value="item.clerk_id"
                >
              {{item.real_name}}
            </a-select-option> 
          </a-select>
        </a-form-model-item> 
      </a-form-model>
      <reserveTip :areShow="showReserveTip" @choose="changeModal" @changeSure="sureYv"/>
    </a-modal>
  </div>
</template>
<script>
import {requestUserClerkList} from '@/request/founding.js';
import {reserveInfo} from '@/request/reserve.js';
import {reserve} from '@/request/reserve.js';
import reserveTip from "./reserveTip";
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  props: ["isShow",'tablename'],
  components: {
     reserveTip 
  },
  data() {
    return {
      guides: [],
      locale: zhCN,
      form: {
          table_id: '', //桌台
          name: '',//顾客姓名
          order_time: '',//预定时间
          mobile: '',//预订电话
          people: '', //就餐人数
          order_clerk_id: undefined,//店员
          date:'',
          time:'',
          confirm:0,
          
        },
        locale: zhCN,
      rules: {
        table_id: [{ required: true, message: '请选择桌台', trigger: 'change' }],
        name: [{ required: true, message: '请输入顾客姓名', trigger: 'change' }],
        order_time: [{ required: true, message: '请选择就餐时间', trigger: 'change' }],
        mobile: [
          { required: true, message: '请填写手机号码', trigger: 'change' },
          { pattern : /^1[3-9]{1}[0-9]{9}$/, message: '电话格式不正确', trigger: 'blur' }
        ],
        // people: [{ required: true, message: '请填写就餐人数', trigger: 'change' }],
        // order_clerk_id: [{ required: true, message: '请选择店员', trigger: 'change' }],
      },
      userArr:[],//店员列表
      date:'',//日期
      time:'',//时间
      showReserveTip:false, //提示modal
      da:''
    };
  },
  watch:{
    isShow:{
      handler(){
        this.form.table_id=this.tablename;
        if(this.isShow){
          this.reserveRequest()
          const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
              _this.handleSubmit()
            } 
          }    
        }
       
      },
      deep:true,
    }
  },
  mounted(){
    this.userRequest()
    //console.log(JSON.stringify(this.da))
  },
  updated(){
    //  console.log(this.showReserveTip)
  },
  methods: {
    handleCancel() {
      this.form.table_id="";
      this.form.name="";
      this.form.order_time="";
      this.form.mobile="";
      this.form.people="";
      this.form.order_clerk_id=""; 
      this.form.date="";
      this.form.time=""; 
      this.form.confirm=0;
      this.$emit('choose',false,true);
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
          if(valid){
              this.reserveRwquest();
           
          }
      });
    },
    userRequest(){ //店员列表请求
      const _this=this;
      requestUserClerkList()
        .then((res) => {
          if(res.data.status===200){
              _this.userArr=res.data.data.list&&res.data.data.list;
            }               
          })
        .catch(result => {
            return false;
      })
    },
    onChange(date, dateString){
      this.date=dateString;
      this.form.order_time=`${this.date} ${this.time}`
    },
    onChanges(date, dateString){
      this.time=dateString;
      this.form.order_time=`${this.date} ${this.time}`
    },
    reserveRwquest(){//新增
      const _this=this;
      reserve(this.form)
        .then((res) => {
          if(res.data.status===200){
               this.$message.success('预订成功',1.5);
              this.form.table_id="";
              this.form.date="";
              this.form.time="";
              this.form.name="";
              this.form.order_time="";
              this.form.mobile="";
              this.form.people="";
              this.form.order_clerk_id="";  
              this.form.confirm=0;
               this.$emit('choose',true,true);
            }else if(res.data.status===500){
              console.log(res.data.message)
              if(res.data.message==="该桌台近一小时已有预约记录，是否确认预约？"){
                this.showReserveTip=true;
              }else{
                this.$message.warn(res.data.message,1.5)
              }
         
            
            }              
          })
        .catch(result => {
            return false;
      })
    },
    changeModal(){
      this.showReserveTip=false;
      const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
              _this.handleSubmit()
            } 
          }  
    },
    sureYv(){
      this.form.confirm=1;
    },
    reserveRequest(){//列表请求
      const _this=this;
       reserveInfo({
        storey_id:'',
        is_temp:1,
         })
        .then((res) => {
          if(res.data.status===200){
              _this.da=res.data.data.list.data && res.data.data.list.data;
            }               
          })
        .catch(result => {
            return false;
      })
    },
  },
 

};
</script>

<style lang="less" scoped>
.myDate {
  width: 100%;
}
.myTime {
  position: fixed;
  margin-left: 1vh;
  width: 10vh;
}
/deep/.ant-btn {
  width: 17vh;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 17vh;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-footer {
  text-align: center;
}
/deep/.ant-modal-header{
  font-size: 1.8vh;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-form-item label {
  font-size: 1.8vh;
}
/deep/.ant-modal-content{
   background:rgba(237, 238, 242, 1);
}
/deep/.ant-time-picker-input{
  height:3.9vh;
  font-size: 1.8vh;
}
/deep/.ant-input{
  height:3.9vh;
}
/deep/.ant-select-selection--single{
  height:3.9vh;
}
/deep/.ant-select-selection__rendered{
  line-height: 3.9vh;
  font-size: 1.8vh;
}

</style>
