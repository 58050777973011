<template>
  <div>
    <a-modal
      :visible="areShow"
      title="预定提示"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handlecancel"
    >
      <p>
        该桌台近一小时已有预约记录，是否确认预约？
      </p>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ["areShow"],
  data() {
    return {
    };
  },
   watch:{
    areShow:{
      handler(){
        if(this.areShow){
          const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { 
              _this.$emit('choose',true)
              _this.$emit('changeSure',true)
            } 
          }    
        }
       
      },
      deep:true,
    }
  },
  methods: {
    handlecancel() {
       this.$emit('choose',true)
    },
    handleOk() {
      this.$emit('choose',true)
      this.$emit('changeSure',true)

    },
  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-btn {
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  font-size: 1.8vh;
  border-radius: 0.5vh;
  margin: 0.5vh 0 0.5vh 2vh;
  color: white;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.ant-modal-content{
   background:rgba(237, 238, 242, 1);
}
</style>